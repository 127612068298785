<template>
  <v-dialog
    v-model="deleteChampionshipModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Tem certeza que deseja excluir o campeonato <b>"{{championshipData.name}}"</b>?
        </div>
      </v-card-title>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            :disabled="saving"
            @click="deleteChampionship()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Sim
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    championshipData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    deleteChampionshipModal: {
      get () {
        return this.$store.state.deleteChampionshipModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteChampionshipModal',
          value: val
        })
      }
    }
  },
  methods: {
    deleteChampionship () {
      this.saving = true
      this.$http.delete(`/championships/${this.championshipData._id}`)
        .then(() => {
          this.saving = false
          this.deleteChampionshipModal = false
          this.$emit('deleted')
          this.$toast.success('Cadastro removido com sucesso.')
        })
        .catch((err) => {
          this.saving = false
          this.$toast.error(err.response.data.message)
        })
    }
  }
}
</script>
